<template>
  <div></div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "AdminLogin",
  async mounted() {
    await this.logout();
    await this.adminLogin();
  },
  methods: {
    async logout() {
      await httpClient.post("/logout");
      localStorage.removeItem("_sct");
      return true;
    },
    async adminLogin() {
      const alk = this.getUrlParameter("k");
      if (!alk) {
        window.location = process.env.VUE_APP_V2_HOME_PATH;
      }

      try {
        const { data } = await httpClient.post("/al", { alk });

        if (!data || !data.token) {
          window.location = process.env.VUE_APP_V2_HOME_PATH;
          return false;
        }

        localStorage.setItem("_sct", data.token);

        this.$vToastify.success(this.$t("messages.login_success"));
        await this.$router.push({ name: "Dashboard" });
        return true;
      } catch (e) {
        window.location = process.env.VUE_APP_V2_HOME_PATH;
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
